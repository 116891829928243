import './css/contact.css';
import React, { useEffect, useState } from 'react';

function Contact() {
    const [clientWidth, setClientWidth] = useState(window.innerWidth);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    /*
    const handleSubmit = () => {
        if (name && email && message) {
            const data = {
                name,
                email,
                message
            };

            fetch('https://itforall.cyrilmorin.fr/contact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data),
            })
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        alert('Votre message a bien été envoyé !');
                        setName('');
                        setEmail('');
                        setMessage('');
                    } else {
                        alert('Une erreur est survenue, veuillez réessayer plus tard.');
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        } else {
            alert('Veuillez remplir tous les champs.');
        }
    };
    */
    const handleSubmit = () => {
        if (name && email && message) {
            window.location.href = `mailto:informations@itforall.fr?subject=Contact%20Form%20Submission&body=Name: ${name}%0DEmail: ${email}%0DMessage: ${message}`;
            setName('');
            setEmail('');
            setMessage('');
        } else {
            alert('Veuillez remplir tous les champs.');
        }
    };


    useEffect(() => {
        document.title = "Contact";

        const handleResize = () => {
            setClientWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="Contact">
            <h1 className='contact-title'>Contactez nous !</h1>
            <div className="Contact-container">
                {clientWidth > 768 &&
                    <img src="images/earth.png" alt="earth" className="earth" />
                }
                <div className="Contact-text">
                    <h2>Une question ? Un projet ? Discutons-en !</h2>
                    <div className="Contact-text-container">
                        <p>Email:</p>
                        <a className='contact-data' href="mailto:informations@itforall.fr"><p className='contact-data'>informations@itforall.fr</p></a>
                    </div>
                    <div className="Contact-text-container">
                        <p>Téléphone:</p>
                        <a className='contact-data' href="tel:+33634253009"><p className='contact-data'>+33 6 34 25 30 09</p></a>
                    </div>
                </div>
            </div>
            <div className="Contact-form">
                <div className="Contact-form-container">
                    <div className="Contact-form-container-input">
                        <div className="Label-and-input">
                            <p>Nom</p>
                            <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
                        </div>
                        <div className="Label-and-input">
                            <p>Email</p>
                            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        <div className="Label-and-input">
                            <p>Message</p>
                            <textarea value={message} onChange={(e) => setMessage(e.target.value)} rows="8" />
                        </div>
                        <button onClick={handleSubmit} className='form-send'>Envoyer</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;