import React from "react"
import "./css/footer.css"
import { Link } from "react-router-dom"

function Footer(params) {
    return (
        <div className="footer">

            <a href="mailto:cyril.morin.tai@gmail.com" target="_blank" rel="noreferrer" className="footer-link">
                <p className="footer-text">Made by Cyril MORIN</p>
            </a>
            <Link to="/contact" className="footer-link">
                <p className="footer-text">Contact</p>
            </Link>
            <Link to="/cookies" className="footer-link">
                <p className="footer-text">RGPD et cookies</p>
            </Link>
        </div>
    )
}

export default Footer