import React, { useEffect } from 'react';
import './css/about.css';

function About(params) {

    useEffect(() => {
        document.title = "A propos";
        window.scrollTo(0, 0)
    }, [])

    return (

        <div className="about">
            <h1 className="about-title">Expert en Systèmes d'information</h1>
            <img src="/images/sebalpha.png" alt="sebastien marguerite" className="about-image" />
            <p className="about-text">
                Expert en systèmes d'information, passionné d'informatique depuis toujours, Sébastien Marguerite est un professionnel expérimenté spécialisé dans la digitalisation et la création d'infrastructures informatiques performantes des coûts optimisés. Son objectif est d'apporter une vision stratégique à votre entreprise en améliorant la sécurité, le support et l'efficacité de vos systèmes.

                </p><p className='about-text'> Avec une solide formation et un parcours professionnel exemplaire, Sébastien a acquis une expertise approfondie dans divers domaines de l'informatique. Ses compétences techniques incluent la gestion des serveurs Windows, Linux et Exchange, ainsi que la maîtrise des environnements variés.

                </p><p className="about-text"> Grâce à son expérience en tant qu'ingénieur avant-vente, chef de projet et consultant en infrastructure, Sébastien est à même de comprendre vos besoins spécifiques et de créer des solutions sur mesure. Sa capacité à mener des migrations et des refontes d'infrastructures, ainsi qu'à optimiser les coûts, garantit des résultats tangibles pour votre entreprise.

                </p><p className="about-text">Tout au long de sa carrière, Sébastien a travaillé au sein d"entreprises de différentes tailles, Enso Group, MisterTemp, Infosea, où il a dirigé des projets complexes, formé des équipes et assuré une veille technologique constante.

                </p><p className="about-text">Aujourd'hui, fort de son expertise et de son expérience approfondie, Sébastien Marguerite est prêt à mettre ses compétences au service de votre entreprise. Que ce soit pour une migration, une refonte de votre SI, il saura vous accompagner avec professionnalisme et engagement.

                </p><p className="about-text">Faites confiance à Sébastien Marguerite pour votre transition digitale. Contactez-le dès maintenant pour discuter de vos besoins et découvrir comment il peut vous aider à atteindre vos objectifs informatiques.
            </p>
        </div>
    );
}

export default About;