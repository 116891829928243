

//write a home component that will be the main page of the website, the background will be the file bghome.jpg


import React, { useEffect, useState } from 'react';
import './css/home.css';

const herosubtitlefull = "La solution IT pour les TPE et PME.";
function Home(params) {
    const [herosubtitle, setherosubtitle] = useState("");
    const [clientWidth, setClientWidth] = useState(window.innerWidth);

    function fillherosubtitle(i) {
        if (i <= herosubtitlefull.length) {
            setherosubtitle(herosubtitlefull.substring(0, i))
            setTimeout(() => {
                fillherosubtitle(i + 1)
            }, 80);
        }
        if (i === herosubtitlefull.length) {
            params.setItforallAnimDone(true);
        }
    }


    useEffect(() => {
        document.title = "Acceuil";
        if (!params.itforallAnimDone) {
            setTimeout(() => {
                fillherosubtitle(0);
            }, 2000);
        } else {
            setherosubtitle(herosubtitlefull);
        }
        const handleResize = () => {
            setClientWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [params.itforallAnimDone]);

    return (
        <div className={`Home ${params.homeAnim ? "Home-displayed" : ""}`}>
            <div className="hero">
                <div className="hero-text">
                    <h1>IT for All</h1>
                    {herosubtitle.length > 0 && <h2>{herosubtitle}</h2>}
                </div>
                <img src="/images/homehero.png" alt="homehero" className="homehero" />
                <p className="hero-text2"><span
                style={{
                    fontWeight: 'bold'
                }}
                >ITforALL</span> est la solution
                    informatique idéale pour les petites et moyennes
                    entreprises. Nous comprenons les défis auxquels
                    vous êtes confrontés et nous nous engageons
                    à fournir des services IT fiables et abordables, souvent réservés aux grandes entreprises.</p>
                    <p className="hero-text2">
                    Que vous ayez besoin d'une assistance technique,
                    d'une gestion de réseau ou de la sécurisation
                    de vos données, notre équipe d'experts est là
                    pour vous accompagner. Faites le choix de la
                    tranquillité d'esprit avec ITforALL
                    et concentrez-vous sur le développement de
                    votre entreprise.</p>
            </div>
            {clientWidth > 768 ? (
                <div className="home-services-wrapper">
                    <div className="home-services">
                        <div className="home-services-text-wrapper">
                            <h2 className="home-services-subtitle">Gouvernance DSI à temps partagé, service personnalisé</h2>
                            <p className="home-services-text">Spécialisés en gouvernance DSI à temps partagé chez ITforALL, nous alignons stratégie digitale, processus et technologies pour une transition numérique fluide. Notre approche personnalisée et notre équipe expérimentée proposent des solutions sur mesure pour vos défis, garantissant la qualité de service et la réussite de vos initiatives.
                            </p>
                        </div>
                        <img src="/images/cogwheelalpha.png" alt="homeservices" className="homeservices" id='cogwheel' />
                    </div>
                    <div className="home-services">
                        <img src="/images/laptopalpha.png" alt="laptop" className="homeservices" id='laptop' />
                        <div className="home-services-text-wrapper">
                            <h2 className="home-services-subtitle">Un parc informatique audité et toujours à jour</h2>
                            <p className="home-services-text"
                            >Notre équipe assure une supervision constante de votre parc informatique, effectuant des audits et mises à jour pour une performance optimale. Nous évaluons efficacement performance, sécurité et points d'amélioration, et fournissons des recommandations personnalisées. Grâce à notre veille technologique, votre infrastructure reste à jour, maximisant ainsi productivité et compétitivité.
                            </p>
                        </div>
                    </div>
                    <div className="home-services">
                        <div className="home-services-text-wrapper">
                            <h2 className="home-services-subtitle">Mise en relation avec des prestataires en fonction du besoin</h2>
                            <p className="home-services-text">Comprendre vos besoins informatiques et vous connecter à des prestataires de confiance est notre expertise. En collaboration avec un réseau de professionnels qualifiés, nous sélectionnons les experts répondant à vos exigences en développement, maintenance, sécurité, etc. Notre approche personnalisée assure une collaboration optimale avec des prestataires compétents et fiables.
                            </p>
                        </div>
                        <img src="/images/headsetalpha.png" alt="homeservices" className="homeservices" id='cogwheel' />
                    </div>
                </div>
            )
                :
                (
                    <div className="mobile-home-services-wrapper">
                        <div className="mobile-home-services">
                            <h2>Gouvernance DSI à temps partagé, service personnalisé</h2>
                            <img src="/images/cogwheelalpha.png" alt="cogwheel" className="homeservices" id='cogwheel' />
                            <p className="mobile-home-services-text">Chez ITforALL, nous sommes spécialisés dans la gouvernance DSI à temps partagé. Notre approche personnalisée s'adapte parfaitement aux besoins de votre entreprise. Avec notre expertise, vous pouvez bénéficier d'une transition fluide vers les systèmes numériques, en alignant stratégie, processus et technologies.
                                Notre équipe expérimentée vous accompagne tout au long du processus, en développant des solutions sur mesure qui répondent à vos défis spécifiques. Nous nous engageons à vous offrir une qualité de service exceptionnelle et à assurer le succès de vos initiatives de gouvernance DSI.
                            </p>
                        </div>
                        <div className="mobile-home-services">
                            <h2>Un parc informatique audité et toujours à jour</h2>
                            <img src="/images/laptopalpha.png" alt="laptop" className="homeservices" id='laptop' />
                            <p className="mobile-home-services-text">
                                La pertinence d'un parc informatique performant et à jour pour assurer le bon fonctionnement de votre entreprise est indéniable. Grâce à notre expertise en audit et mise à jour des infrastructures informatiques, nous vous offrons une expérience informatique optimale. Notre équipe qualifiée réalise des audits approfondis de votre parc informatique, évaluant sa performance, sa sécurité et son efficacité. En identifiant les lacunes et les opportunités d'amélioration, nous vous fournissons des recommandations personnalisées pour optimiser votre infrastructure. Nous nous engageons également à maintenir votre parc informatique à jour en suivant les dernières avancées technologiques. Grâce à notre veille technologique constante, nous maximisons votre productivité et votre compétitivité sur le marché.
                            </p>
                        </div>
                        <div className="mobile-home-services">
                            <h2>Mise en relation avec des prestataires en fonction du besoin</h2>
                            <img src="/images/headsetalpha.png" alt="headset" className="homeservices" id='headset' />
                            <p className="mobile-home-services-text">Nous comprenons l'importance d'accéder à des prestataires de confiance pour vos besoins informatiques. Notre expertise consiste à mettre en relation avec les meilleurs prestataires selon vos exigences. En collaboration avec un vaste réseau de prestataires qualifiés, nous vous connectons aux professionnels les mieux adaptés. Qu'il s'agisse de développement, maintenance, sécurité ou autres solutions informatiques, nous sélectionnons les experts qui répondent à vos attentes. Notre approche personnalisée comprend vos besoins spécifiques pour proposer des prestataires compétents et fiables. Nous nous engageons à fournir un service de qualité supérieure, facilitant la collaboration avec les prestataires recommandés.
                            </p>
                        </div>
                    </div>
                )

            }
        </div>
    );
}

export default Home;



