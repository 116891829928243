import React from 'react'
import './css/cookies.css'

function Cookies() {
    return (
        <div className='cookies'>
            <h1 className="cookies-title">RGPD et cookies</h1>
            <p class="cookies-text">Le site itforall.fr n'utilise pas de cookies.</p>
            <p class="cookies-text">Le site itforall.fr ne collecte pas de données personnelles.</p>
            <p class="cookies-text">Le site itforall.fr ne collecte pas de données de navigation.</p>
            <p class="cookies-text">Le site itforall.fr ne collecte pas de données de géolocalisation.</p>
            <p class="cookies-text">Le site itforall.fr ne collecte pas de données de connexion.</p>
        </div>
    )
}

export default Cookies